import { render, staticRenderFns } from "./BusinessSector.vue?vue&type=template&id=5a632dbc&scoped=true&"
import script from "./BusinessSector.vue?vue&type=script&lang=js&"
export * from "./BusinessSector.vue?vue&type=script&lang=js&"
import style0 from "./BusinessSector.vue?vue&type=style&index=0&id=5a632dbc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a632dbc",
  null
  
)

export default component.exports